.theme-blockchainCrypto {
  background: #508281;
}
.theme-blockchainCrypto .on-hero-bg .hero-flex .pic {
  color: #4e7473;
}
.theme-blockchainCrypto .off-hero-bg .hero-flex .pic {
  color: #508281;
}
.theme-blockchainCrypto .yliam-btn {
  color: #508281;
}
.theme-blockchainCrypto .md\:w-5\/12 p {
  background: #5b9291;
}
.theme-blockchainCrypto .project-header {
  border-color: #5b9291;
}
.theme-blockchainCrypto .project-header span {
  background: #508281;
}
.theme-blockchainCrypto .pro-sidebar > .pro-sidebar-inner {
  background: #5b9291;
}
.theme-blockchainCrypto p {
  border-radius: 1%;
  font-size: 20px;
  text-align: left;
  padding: 15px;
}
.theme-blockchainCrypto .loading {
  animation: ani-blockchainCrypto 1s infinite;
}
@keyframes ani-blockchainCrypto {
  to {
    background: #508281;
  }
}

.theme-blockchainCrypto h3 {
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
}
