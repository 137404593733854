.theme-lmt {
  background: #003b49;
}
.theme-lmt .on-hero-bg .hero-flex .pic {
  color: #113e49;
}
.theme-lmt .off-hero-bg .hero-flex .pic {
  color: #003b49;
}
.theme-lmt .yliam-btn {
  color: #003b49;
}
.theme-lmt .md\:w-5\/12 p {
  background: #084b5b;
}
.theme-lmt .project-header {
  border-color: #084b5b;
}
.theme-lmt .project-header span {
  background: #003b49;
}
.theme-lmt .pro-sidebar > .pro-sidebar-inner {
  background: #084b5b;
}
.theme-lmt p {
  border-radius: 1%;
  font-size: 20px;
  text-align: left;
  padding: 15px;
}
.theme-lmt .loading {
  animation: ani-lmt 1s infinite;
}
@keyframes ani-lmt {
  to {
    background: #003b49;
  }
}

.theme-lmt h3 {
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
}
