.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-size: 20px;
  padding: 15px;
}
p img {
  margin: 10px 0px;
  width: 100%;
}
.modal.show .modal-dialog {
  max-width: 800px;
  width: 90%;
  margin: auto;
  padding: 0px;
}
.modal-content {
  background: inherit;
  width: 100%;
}
.hero-image {
  width: 100%;
  min-height: 100vh; /* You must set a specified height */
  overflow: hidden;
  /* padding-top: 85px; */
}
.blur-featured {
  width: 100%;
  z-index: 2;
  top: 0vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background: #0f0f0f;
}
.on-hero-bg {
  /* background: rgba(73, 71, 71, 0.238); */
}
hr {
  border: 0;
  border-top: 1px solid #88898b;
  margin-bottom: 30px;
}

.button {
  display: inline-flex;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 600;
  padding: 11px 31px;
  height: 48px;
  text-decoration: none !important;
  color: #fff;
  background-color: #ff325e;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;
}

.shake {
  position: relative;
  animation: 0.5s shake alternate;
}
.shake2 {
  position: relative;
  animation: 0.5s shake2 alternate;
}
@keyframes shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
@keyframes shake2 {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
.hero-social {
  margin-top: 20px;
  font-size: 20px;
  align-items: center;
  display: flex;
}
.hero-texts {
  margin-right: 20px;
  font-weight: 300;
}
.hero-texts h3 {
  font-weight: 700;
  font-size: 28px;
  word-spacing: 1px;
  display: flex;
  align-items: center;
}
.hero-heading {
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
}
.hero-medal {
  font-size: 18px;
  opacity: 0.9;
}
.hero-texts h3 svg {
  margin-right: 5px;
}
.hero-texts a {
  font-weight: 500;
  width: max-content;
  display: inline-block;
}
.hero-texts span {
  font-size: 22px;
}
.hero-social a {
  display: inline-block;
  transition: all 0.25s;
  border-bottom: 0px;
}
.hero-social a :hover {
  scale: 0.99;
}
.hero-social a svg {
  width: 35px;
  height: 35px;
  display: inline-block;
}
.hero-social a svg :hover {
  color: #1bec0c;
}
.hero-flex {
  text-align: left;
  margin: auto;
  display: flex;
  width: 90%;
  max-width: 1500px;
  justify-content: space-between;
  align-items: end;
}
.exp-org {
  display: flex;
  align-items: center;
  font-size: 26px;
}
.exp-org svg,
.exp-location svg {
  margin-right: 5px;
  color: #ffffff;
}
.exp-location {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.exp-dates {
  font-size: 20px;
  opacity: 0.8;
}
.exp-tools {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.exp-tools svg {
  margin-right: 5px;
}
.project-summary {
  font-size: 20px;
}
.project-preview {
  display: flex;
  flex-direction: row;
}
.project-preview div {
  margin-right: 30px;
  width: 60px;
  height: 60px;
  background: #00000094;
  box-shadow: 0px 0px 10px 0px #83838359;
  border-radius: 10px;
}
.project-preview div :hover {
  border: 1px dotted;
  border-radius: 10px;
  cursor: pointer;
}
.project-preview div img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* .hero-flex .pic :hover{ */
/* scale: 0.9; */
/* background-image: url('../public/william1.png'); */
/* background-image: linear-gradient(to bottom, rgba(71, 80, 119, 0.52), rgba(71, 80, 119, 0.333)),url('../public/william1.png'); */
/* } */
.on-hero-bg .hero-flex .pic {
  color: #242424;
}
.off-hero-bg .hero-flex .pic {
  color: #242424;
}
.hero-flex .pic {
  scale: 0.9;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 270px;
  height: 270px;
}
.hero-pic-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-image: url("/public/images/liam.png");
  opacity: 0.7;
}
.pic :hover {
  opacity: 0.8;
  background-image: url("/public/images/liam.png");
}

.hero-circle-container {
  width: 260px;
  height: 260px;
  position: absolute;
  clip: rect(0px, 260px, 260px, 130px);
}
.hero-circle {
  width: 260px;
  height: 260px;
  border: 15px solid;
  border-color: initial;
  border-radius: 50%;
  position: absolute;
  clip: rect(0px, 130px, 260px, 0px);
}
.hero-circle1 {
  width: 280px;
  height: 280px;
  clip: rect(0px, 160px, 280px, 0px);
}
div[data-anim~="base"] {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
}

.hero-circle-container[data-anim~="container"] {
  -webkit-animation-duration: 0s;
  -webkit-animation-delay: 0s;
  -webkit-animation-name: close-container;
}

.hero-circle[data-anim~="left"] {
  -webkit-animation-duration: 0s;
  -webkit-animation-name: left-spin;
}

.hero-circle[data-anim~="right"] {
  -webkit-animation-duration: 0s;
  -webkit-animation-name: right-spin;
}

.hero-circle[data-anim~="left1"] {
  -webkit-animation-duration: 0s;
  -webkit-animation-name: left-spin1;
}
.hero-circle[data-anim~="right1"] {
  -webkit-animation-duration: 0s;
  -webkit-animation-name: right-spin1;
}

div[data-anim~="base1"] {
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
}

.hero-circle-container[data-anim~="container1"] {
  -webkit-animation-duration: 0s;
  -webkit-animation-delay: 0s;
  -webkit-animation-name: close-container1;
}

@keyframes right-spin1 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-45deg);
  }
}

@keyframes left-spin1 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(170deg);
  }
}

@keyframes right-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-35deg);
  }
}

@keyframes left-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(170deg);
  }
}

@keyframes close-container {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}

@keyframes close-container1 {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}

.hero-dots-anim {
  animation: heroBounce 2s linear infinite;
}
.hero-prev {
  margin-left: 5px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row-reverse;
}
.hero-prev svg {
  margin-left: 5px;
}
.hero-next {
  margin-right: 10px;
  text-transform: uppercase;
  display: flex;
}
.hero-prev,
.hero-next {
  position: relative;
  color: rgba(255, 255, 255, 0.719);
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
}
.hero-next {
  animation: heroBounce 2s linear infinite;
  color: #10ff00;
}
@keyframes heroBounce {
  0%,
  100% {
    right: 0;
  }
  50% {
    right: 5px;
  }
}
.yliam-btn {
  background: #1bec0c;
  color: #1a1a1a;
  /* border: 1px solid #10ff00; */
  font-weight: 700;
  padding: 15px 40px;
  border-radius: 50px;
}
.yliam-btn1 {
  margin-right: 20px;
  background: #1a1a1aa4;
  color: #10ff00;
  border: 1px solid #10ff00;
  font-weight: 700;
  padding: 15px 20px;
  border-radius: 8px;
}
.y-green {
  color: #10ff00;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li {
  margin-top: 2px;
  font-size: 20px;
}
.code {
  font-family: Consolas, "courier new";
  color: #bebebe;
  background-color: #272727;
  padding: 10px;
  border-radius: 5px;
  word-break: break-all;
}
.code div {
  margin-bottom: 10px;
}
.project-header {
  border-bottom: 0.1px solid #939599;
  line-height: 0.1em;
  text-align: left;
}

element.style {
}
.theme-wumpus .project-header span {
  background: #613100;
}
.project-header span {
  background: #49494b;
  padding-left: 10px;
  padding-right: 10px;
}
.filter-projects {
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 16px;
  margin: 0px 5px;
  background-color: inherit;
  cursor: pointer;
}

.filter-projects:hover {
  background-color: rgba(255, 255, 255, 0.391);
}

.filter-projects.active {
  border-bottom: 2px solid rgba(173, 173, 173, 0.489);
}
.blink-anim {
  animation: blinker 5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker {
  to {
    opacity: 1;
    background: #1a1a1a;
    color: #ffffff !important;
  }
}
@media (max-width: 800px) {
  .profile-hero {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .hero-tech {
    justify-content: center;
  }
  .profile-hero .hero-social,
  .profile-hero h3 {
    justify-content: center;
  }
  .hero-flex {
    flex-direction: column-reverse;
  }
  .hero-flex .pic {
    margin-bottom: 20px;
    align-self: center;
    width: 150px;
    height: 150px;
  }
  .hero-texts {
    margin-right: 0px;
  }
  .project-preview div {
    width: 46%;
    max-width: 60px;
    max-height: 60px;
  }
  .profile-hero .project-preview {
    justify-content: center;
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .navbar-expand-lg {
    display: none !important;
  }
  .hero-image {
    padding-top: 0px;
  }
}
