.theme-bam {
  background: #475077;
}
.theme-bam .on-hero-bg .hero-flex .pic {
  color: #474e6c;
}
.theme-bam .off-hero-bg .hero-flex .pic {
  color: #475077;
}
.theme-bam .yliam-btn {
  color: #475077;
}
.theme-bam .md\:w-5\/12 p {
  background: #5b6692;
}
.theme-bam .project-header {
  border-color: #5b6692;
}
.theme-bam .project-header span {
  background: #475077;
}
.theme-bam .pro-sidebar > .pro-sidebar-inner {
  background: #5b6692;
}
.theme-bam p {
  border-radius: 1%;
  font-size: 20px;
  text-align: left;
  padding: 15px;
}
.theme-bam .loading {
  animation: ani-bam 1s infinite;
}
@keyframes ani-bam {
  to {
    background: #475077;
  }
}

.theme-bam h3 {
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
}
