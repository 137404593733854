@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: #242424;
  font-family: Georgia;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: circle;
}
a,
a:hover {
  color: inherit;
}
details {
  padding: 1rem 0px;
  background: inherit;
  text-align: left;
  border-radius: 0.25rem;
  margin-bottom: 10px;
}
summary {
  list-style-type: "▪ ";
  color: white;
  cursor: pointer;
  position: relative;
  outline: none;
  font-size: 20px;
}
/* summary::after {
  content: "⬇";
  border-radius: 0.25rem;
  padding: 0.5rem;
  position: absolute;
  right: 0.25rem;
  top: -0.5rem;
  color: white;
} */
details[open] {
  background: inherit;
}
/* details[open] summary::after {
  content: "➖";
} */
details .company {
  font-size: 20px;
}

details .country {
  font-size: 18px;
  border-bottom-width: 1px;
  border-color: rgb(59, 59, 59);
  padding-bottom: 5px;
  color: rgb(186, 186, 186);
}
.details-body {
  /* background:#ffffff1f; */
  /* padding:5px; */
  font-size: 20px;
}
.navbar {
  z-index: 3;
  top: 0;
  font-weight: 700;
  /* background: rgba(73, 71, 71, 0.238); */
}
.navbar-brand {
  cursor: pointer;
}
.navbar-yl a {
  margin-left: 50px;
  text-decoration: none;
}
.navbar-yl {
  align-items: center;
}
.navbar-nav a :hover {
  scale: 0.98;
}

.navbar-socials a :hover {
  color: #ff00cc;
}
.navbar-nav a {
  padding: 8px 0px;
}
.navbar .container {
  min-width: 100%;
}
.footer {
  color: white;
  text-align: left;
  display: flex;
  padding: 0px 10px;
  justify-content: space-between;
}
.footer-head {
  text-transform: uppercase;
  color: rgb(235, 235, 235);
  border-bottom: 1px solid rgb(235, 235, 235);
  font-weight: 600;
  font-size: 18px;
}
.footer li {
  font-size: 16px;
}
@media (max-width: 800px) {
  .footer {
    flex-direction: column;
  }
  .footer div {
    margin-top: 20px;
  }
}
