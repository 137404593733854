*.hidden {
  display: none !important;
}

div.loading {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #191919;
}

@keyframes uil-ring-anim {
  0% {
    width: 0%;
  }
  20% {
    width: 10%;
  }
  30% {
    width: 15%;
  }
  40% {
    width: 18%;
  }
  50% {
    width: 25%;
  }
  60% {
    width: 30%;
  }
  70% {
    width: 36%;
  }
  80% {
    width: 60%;
  }
  90% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
}
.uil-ring-css {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}
.uil-ring-css .spin-load {
  position: absolute;
  display: block;
  width: 160px;
  height: 120px;
  top: 0px;
  border-bottom: 10px dotted #ffffff;
  animation: uil-ring-anim 1s linear infinite;
}
.uil-ring-css img {
  content: url("/public/logoWhite.png");
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  margin: auto;
  width: 50%;
  height: 50%;
  border-radius: 50%;
}
